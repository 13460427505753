export async function getShopInfo(sessionUser) {
    let getting = true;
    let errorGetStyle = null;
    try {
        const userId = sessionUser.idToken?.payload?.sub;
        const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/shop`;
        const response = await fetch(url, { method: 'GET' });

        const jsonRes = await response.json();

        if (response.ok) {
            return jsonRes;
        } else {
            throw new Error(jsonRes.error || jsonRes.message);
        }
    } catch (error) {
        console.error('店舗データ取得失敗', error);
        errorGetStyle = '店舗データ取得に失敗しました';
    } finally {
        getting = false;
    }
}