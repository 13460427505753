<template>

    <div class="container text-start">
        <h2 class="text-center mb-4">店舗情報登録</h2>

        <!-- <p v-if="getting">読み込み中...</p> -->
        <div v-if="shopInfo">
            <div v-if="shopInfo.password_mismatch" class="alert alert-danger" role="alert">
            SALON BOARDログインIDとパスワードを確認してください。<br />
            </div>
            <div class="card mb-4">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <label style="width: 30%;">店舗名</label>
                        <input type="text" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="shopInfo.shop_name">
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">住所</label>
                        <input type="text" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="shopInfo.shop_address">
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">担当者</label>
                        <input type="text" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="shopInfo.manager_name">
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">電話番号</label>
                        <input type="text" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="shopInfo.tel">
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">SalonBoard ID</label>
                        <input type="text" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="shopInfo.salon_board_id">
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">SalonBoard<br>パスワード</label>
                        <input type="password" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="shopInfo.salon_board_pass">
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">投稿タイミング</label>
                        <select id="style_imege2_type" v-model="shopInfo.post_interval">
                            <option v-for="item in postIntervalTypes" :key="item" :value="item">{{ item }}</option>
                        </select>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">投稿時刻</label>
                        <select id="style_imege2_type" v-model="shopInfo.post_time">
                            <option v-for="item in postTimeTypes" :key="item" :value="item">{{ item }}</option>
                        </select>時台
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">今すぐ投稿</label>
                        <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                            id="styleRegistFormat0" class="styleRegistFormatRadio"
                            v-model="shopInfo.post_soon" />
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">ブログAI使用</label>
                        <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                            id="styleRegistFormat0" class="styleRegistFormatRadio"
                            v-model="shopInfo.use_ai" />
                    </li>
                    <li class="list-group-item">
                        <label style="width: 30%;">SalonBoard<br>選択肢更新</label>
                        <span v-if="defaultIsWaitingChoices" class="text-center">
                            更新中(最大10分かかります)
                        </span>
                        <span v-if="!defaultIsWaitingChoices">
                        <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="shopInfo.is_waiting_choices">
                        </span>
                    </li>
                </ul>
            </div>
            <form @submit.prevent="submitSaveShopInfoForm" class="needs-validation">
                <button type="submit" class="btn btn-primary btn-block">
                    登録
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getShopInfo } from '../commonMethods.js';

export default {
    data() {
        return {
            shopInfo: null,
            caption: '',
            media: null,
            errorSavePost: null,
            saveSuccess: null,
            saving: false,
            defaultIsWaitingChoices: false,

            posts: null,
            maxPostCounts: 20,
            getting: null,
            errorGetStyle: null,

            deleting: false,
            postToDelete: null,
            errorDeletePost: null,
            deleteSuccess: null,

            mediaBucketUrl: process.env.VUE_APP_MEDIA_BUCKET_URL,
            validVideoFileTypes: ['video/mp4', 'video/quicktime'],
            validImageFileTypes: ['image/jpeg'],
            postIntervalTypes: ['一時停止','毎日同時刻', '1時間毎', '2時間毎', '4時間毎', '12時間毎', '24時間毎'],
            postTimeTypes: Array.from({ length: 24 }, (v, k) => k),
            registrationFormatTypes: ['画像', '動画'],
        };
    },
    computed: {
        ...mapGetters([
            'sessionUser'
        ]),
    },
    async mounted() {
        const jsonRes = await getShopInfo(this.sessionUser);
        this.shopInfo = jsonRes.shopInfo;
        if (this.shopInfo == null) {
            this.shopInfo = {
                id: 0,
                shop_name: "",
                shop_address: "",
                manager_name: "",
                tel: "",
                salon_board_id: "",
                salon_board_pass: "",
                post_interval: "",
                post_time: "",
                use_ai: true,
                status: 0,
                is_waiting_choices: false,
                post_soon: false,
            };
        }
        this.defaultIsWaitingChoices = this.shopInfo.is_waiting_choices;
    },
    methods: {
        // async getShopInfo() {
        //     this.getting = true;
        //     this.errorGetStyle = null;
        //     try {
        //         const userId = this.sessionUser.idToken?.payload?.sub;
        //         const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/shop`;
        //         const response = await fetch(url, { method: 'GET' });

        //         const jsonRes = await response.json();

        //         if (response.ok) {
        //             return jsonRes.shopInfo;
        //         } else {
        //             throw new Error(jsonRes.error || jsonRes.message);
        //         }
        //     } catch (error) {
        //         console.error('店舗データ取得失敗', error);
        //         this.errorGetStyle = '店舗データ取得に失敗しました';
        //     } finally {
        //         this.getting = false;
        //     }
        // },

        async submitSaveShopInfoForm() {
            this.saving = true;

            try {
                const userId = this.sessionUser?.idToken?.payload?.sub;
                this.shopInfo.user_id = userId;
                const styleMetadata = await this.saveShopInfoMetadata({
                    userId: userId,
                    shopInfo: this.shopInfo,
                });
               
                this.$router.push('/posts');
            } catch (error) {
                console.error('登録失敗', error);
                this.errorSavePost = '登録に失敗しました。再度お試しください';
            } finally {
                this.saving = false;
            }
        },

        

        async saveShopInfoMetadata({ userId, shopInfo }) {
            try {
                if (!userId) {
                    throw new Error('セッションが正しくありません');
                }
                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/shop`;
                const response = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        shopInfo: this.shopInfo,
                    }),
                });
                const jsonRes = await response.json();
                if (response.ok) {
                    return jsonRes;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('メタデータの登録失敗', error);
                this.errorSavePost = 'アップロードに失敗しました';
            }
        },
    }
};
</script>

<style>
.image-placeholder {
width: 100%;
padding-bottom: 75%; /* 4:3の縦横比を維持 */
background-color: #e0e0e0;
display: flex;
align-items: center;
justify-content: center;
border: 2px dashed #ccc;
}

.image-placeholder::before {
color: #aaa;
font-size: 1.5rem;
}

.image-container {
width: 100%;
}

.img-fluid {
max-width: 100%;
height: auto;
}
</style>
