<template>

    <div class="container text-start">
        <h2 class="text-center mb-4">ブログ登録</h2>

        <!-- <p v-if="getting">読み込み中...</p> -->
        <div v-if="blog">
            <div v-if="blog.last_error != null" class="alert alert-danger" role="alert">
                SALON BOARD投稿時にエラーが発生しました。<br />
                <pre>{{ blog.last_error }}</pre>
            </div>
            <div class="card mb-4">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <label style="width: 20%;">投稿者</label>
                        <select id="color" v-model="blog.contributor_id">
                            <option v-for="stylistName in stylistNameList" :key="stylistName.name" :value="stylistName.salonboard_id">{{
                                stylistName.name }}</option>
                        </select>
                        <Required :value="blog.contributor_id" />
                    </li>
                    <li class="list-group-item">
                        <label for="length" style="width: 20%;">カテゴリ</label>
                        <select id="length" v-model="blog.category">
                            <option v-for="item in categoryTypes" :key="item" :value="item">{{ item }}</option>
                        </select>
                        <Required :value="blog.category" />
                    </li>
                    <li class="list-group-item">
                        <label style="width: 20%;">タイトル</label>
                        <input type="text" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio w-50" v-model="blog.title">
                            <CharCount :value="blog.title" :maxLength="maxLength.title" />
                            <Prohibited :value="blog.title" />
                            <Required :value="blog.title" />

                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-2">本文</div>
                            <div class="col-10">
                                <div>
                                    <label for="blogContent">ブログ内容:</label>
                                    <div id="blogContent" contenteditable="true" ref="blogContent"
                                        @input="ensureDivWrapping" class="content-editable"></div>
                                </div>

                                <!-- 画像挿入用フィールド -->
                                <div>
                                    <label for="imageUpload">画像を挿入 (最大4枚):</label>
                                    <input type="file" id="imageUpload" @change="handleImageUpload" multiple>
                                </div>
                                <!--
                            <textarea type="text-area" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" style="width:100%"
                                v-model="blog.text"></textarea>
                            -->
                            
                            文字数{{this.blogContentTextCount}}/{{maxLength.text}}　
                            改行数{{this.blogContentLineBreakCount}}/{{maxLength.lineBreak}} 
                            <Prohibited :value="this.blogContentText" />
                            <Required :value="this.blogContent" />
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <label for="length" style="width: 20%;">クーポン</label>
                        <select id="length" v-model="blog.coupon">
                            <option v-for="item in couponList" :key="item" :value="item">{{ item }}</option>
                        </select>
                    </li>
                </ul>
            </div>
            <div class="row">
                <div class="col-2">
                    <form @submit.prevent="submitSaveBlogForm" class="needs-validation">
                        <button type="submit" class="btn btn-primary w-100"
                            :disabled="saving || (posts && posts.length >= maxPostCounts)">
                            {{ saving ? '登録中...' : '登録' }}
                        </button>
                    </form>
                </div>
                <div class="col-1">
                    　
                </div>
                <div class="col-2">
                    <form @submit.prevent="submitSaveStyleForm" class="needs-validation">
                        <button @click="submitDeleteBlogForm(post)" :disabled="deleting" class="btn btn-danger w-100">
                            {{ deleting ? '削除中' : '削除' }}
                        </button>
                    </form>
                </div>
            </div>



        </div>
    </div>
</template>

<script>
import CharCount from '../components/CharCount.vue'; 
import Required from '../components/Required.vue'; 
import Prohibited from '../components/Prohibited.vue'; 
import { mapGetters } from 'vuex';
import { getShopInfo } from '../commonMethods.js';

export default {
    components: {
        CharCount,
        Required,
        Prohibited,
    },
    data() {
        return {
            blogId: null,
            blog: null,
            caption: '',
            media: null,
            errorSavePost: null,
            saveSuccess: null,
            saving: false,
            deleting: false,
            posts: null,
            maxPostCounts: 20,
            getting: null,
            errorGetStyle: null,
            blogContent: '',
            images: [],
            localImageFiles: [],
            nextFileId: 0, // 新しいファイルIDを管理するためのカウンタ

            deleting: false,
            postToDelete: null,
            errorDeletePost: null,
            deleteSuccess: null,

            maxLength: {
                title: 30,  // 30文字-自動投稿識別文字列分3文字
                text: 900,
                lineBreak: 80,
            },

            blogContentText: "",
            blogContentTextCount: 0,
            blogContentLineBreakCount: 0,
            mediaBucketUrl: process.env.VUE_APP_MEDIA_BUCKET_URL,
            validVideoFileTypes: ['video/mp4', 'video/quicktime'],
            validImageFileTypes: ['image/jpeg'],
            registrationFormatTypes: ['画像', '動画'],
            styleTypes: ['FRONT', 'SIDE', 'BACK', 'ARRANGE', 'BEFORE', 'FASHION'],
            categoryTypes: ['こだわりの仕事道具', 'おすすめスタイル', 'サロンのNEWS', 'おすすめメニュー', '仕事の出来事', 'プライベート', 'マイペット', 'お気に入りアイテム', '趣味・マイブーム', 'ビューティー', 'その他'],
            stylistNameList: [],
            couponList: [],
        };
    },
    computed: {
        ...mapGetters([
            'sessionUser'
        ]),
    },
    async mounted() {
        var shopInfoJson = await getShopInfo(this.sessionUser);
        if (shopInfoJson != null) {
            this.stylistNameList = shopInfoJson.stylists2;
            this.couponList = shopInfoJson.coupons;
        }
        if (this.blogId) {
            this.blog = await this.getBlog();
            // プレースホルダーをHTMLタグに置換
            const imageUrls = [this.blog.image1_url, this.blog.image2_url, this.blog.image3_url, this.blog.image4_url]; // 任意の画像URLを配列に格納
            let imageIndex = 0;
            let contentFromDatabase = this.blog.text;
            while (contentFromDatabase.includes('[IMAGE_PLACEHOLDER]')) {
                // 新しい File オブジェクトを作成し、localImageFiles に追加
                const parts = imageUrls[imageIndex].split('.');
                const extension = parts[parts.length - 1];
                const imageBlob = await fetch(imageUrls[imageIndex]).then(res => res.blob());
                const imageFile = new File([imageBlob], imageUrls[imageIndex], { type: `image/${extension}` });
                const fileId = this.nextFileId++;
                this.nextFileId++; // ファイルIDをインクリメント
                this.localImageFiles.push({ file: imageFile, id: fileId }); // ファイルとIDを保存

                contentFromDatabase = contentFromDatabase.replace('[IMAGE_PLACEHOLDER]', `<img src="${imageUrls[imageIndex]}" alt="画像"  style="max-width: 100px; max-height: 100px; object-fit: cover;" data-file-id="${fileId}" />`);
                imageIndex++;
            }
            let contentWithHtml = contentFromDatabase.replace(/\[BR_IMAGE_PLACEHOLDER\]/g, '<br>');
            // contenteditableな<div>にセット
            this.$nextTick(() => {
                this.$refs.blogContent.innerHTML = contentWithHtml;
                this.updateContent();
            });
        } else {
            this.blog = {
                id: 0,
                contributor_id: "",
                category: "",
                title: "",
                image1_url: "",
                image2_url: "",
                image3_url: "",
                image4_url: "",
                text: "",
                coupon: "",
                last_error: null,
            };
        }
    },
    created() {
        this.blogId = this.$route.params.blogId;
    },
    methods: {
        // async getShopInfo() {
        //     try {
        //         const userId = this.sessionUser.idToken?.payload?.sub;
        //         const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/shop`;
        //         const response = await fetch(url, { method: 'GET' });

        //         const jsonRes = await response.json();

        //         if (response.ok) {
        //             return jsonRes;
        //         } else {
        //             throw new Error(jsonRes.error || jsonRes.message);
        //         }
        //     } catch (error) {
        //         console.error('店舗データ取得失敗', error);
        //         this.errorGetStyle = '店舗データ取得に失敗しました';
        //     } finally {
        //         this.getting = false;
        //     }
        // },
        async getBlog() {
            this.getting = true;
            this.errorGetStyle = null;

            try {
                const userId = this.sessionUser.idToken?.payload?.sub;
                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/blog/${this.blogId}`;
                const response = await fetch(url, { method: 'GET' });

                const jsonRes = await response.json();

                if (response.ok) {
                    return jsonRes.blog;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('ブログデータ取得失敗', error);
                this.errorGetStyle = 'ブログデータ取得に失敗しました';
            } finally {
                this.getting = false;
            }
        },

        async submitSaveBlogForm() {
            this.clearMessages();
            this.saving = true;
            let blogId;

            try {
                // 表示されている順番でlocalImageFilesを並べ替え
                await this.updateLocalImageFilesOrder();

                /*
                // 画像プレースホルダーと改行プレースホルダーを置換
                let contentWithPlaceholders = this.blogContent.replace(/<img[^>]*>/g, '[IMAGE_PLACEHOLDER]');
                contentWithPlaceholders = contentWithPlaceholders.replace(/<br>/g, '[BR_IMAGE_PLACEHOLDER]').replace(/\n/g, '[BR_IMAGE_PLACEHOLDER]');
                this.blog.text = contentWithPlaceholders;
                */

                // 純粋なテキストを行ごとにDIVで囲む
                this.blog.text = "";
                this.blogContentText.split('\n').forEach(line => {
                    this.blog.text += `<div>${line}</div>`;
                })

                // 投稿IDを取得するため先に投稿のメタデータをDBに保存
                const userId = this.sessionUser?.idToken?.payload?.sub;
                this.blog.user_id = userId;
                if (this.localImageFiles.length > 0) {
                }
                const blogMetadata = await this.saveBlogMetadata({
                    userId: userId,
                    blog: this.blog,
                });


                // 画像をS3にアップロードするための署名付きURLを取得
                blogId = blogMetadata.id;
                this.blog.id = blogId;
                for (let i = 0; i < this.localImageFiles.length; i++) {
                    const form = new FormData();
                    const extension = this.localImageFiles[i].file.name.split('.').pop().toLowerCase();
                    const presigned = await this.getPresignedUrl({
                        userId,
                        postId: `b${blogId}_${i + 1}`,
                        extension: extension
                    });
                    if (i == 0) {
                        this.blog.image1_url = this.mediaBucketUrl + '/' + this.sessionUser?.idToken?.payload?.sub + '/b' + blogId + '_1.' + extension;
                    } else if (i == 1) {
                        this.blog.image2_url = this.mediaBucketUrl + '/' + this.sessionUser?.idToken?.payload?.sub + '/b' + blogId + '_2.' + extension;
                    } else if (i == 2) {
                        this.blog.image3_url = this.mediaBucketUrl + '/' + this.sessionUser?.idToken?.payload?.sub + '/b' + blogId + '_3.' + extension;
                    } else if (i == 3) {
                        this.blog.image4_url = this.mediaBucketUrl + '/' + this.sessionUser?.idToken?.payload?.sub + '/b' + blogId + '_4.' + extension;
                    }


                    Object.keys(presigned.fields).forEach(key => {
                        form.append(key, presigned.fields[key]);
                    });
                    form.append('file', this.localImageFiles[i].file);
                    const result = await this.uploadMediaFiles({
                        presignedUrl: presigned.url,
                        form
                    });
                }
                await this.saveBlogMetadata({
                    userId: userId,
                    blog: this.blog,
                });

                this.$router.push('/blogs');
            } catch (error) {
                console.error('登録失敗', error);
                this.errorSavePost = '登録に失敗しました。再度お試しください';
            } finally {
                this.saving = false;
            }
        },
        async submitDeleteBlogForm() {
            this.clearMessages();
            this.deleting = true;

            try {
                // 投稿IDを取得するため先に投稿のメタデータをDBに保存
                const userId = this.sessionUser?.idToken?.payload?.sub;
                this.blog.user_id = userId;
                const response = await this.deleteBlog({
                    userId: userId,
                    blog: this.blog,
                });

                this.$router.push('/blogs');
            } catch (error) {
                console.error('削除失敗', error);
                this.errorSavePost = '削除に失敗しました。再度お試しください';
            } finally {
                this.saving = false;
            }
        },



        async saveBlogMetadata({ userId, blog }) {
            try {
                if (!userId) {
                    throw new Error('セッションが正しくありません');
                }

                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/blog`;

                const response = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        blog: this.blog,
                    }),
                });
                const jsonRes = await response.json();

                if (response.ok) {
                    return jsonRes;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('メタデータの登録失敗', error);
                this.errorSavePost = 'アップロードに失敗しました';
            }
        },
        async deleteBlog({ userId, blog }) {
            try {
                if (!userId) {
                    throw new Error('セッションが正しくありません');
                }

                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/blog/${blog.id}`;

                const response = await fetch(url, {
                    method: 'DELETE',
                });
                const jsonRes = await response.json();

                if (response.ok) {
                    return jsonRes;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('削除失敗', error);
                this.errorSavePost = '削除に失敗しました';
            }
        },
        getTextfromHtml(htmlString) {
            // HTML文字列をDOM要素としてパース
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');
            
            // 再帰的に要素を巡りながらテキストに変換
            function elementToText(element) {
                let text = '';
                // 各子要素を処理
                element.childNodes.forEach(child => {
                    if (child.nodeType === Node.TEXT_NODE) {
                        // テキストノードならそのままテキストを追加
                        text += child.textContent;
                    } else if (child.nodeType === Node.ELEMENT_NODE) {
                        // 特定の要素の場合は改行を追加
                        if (['BR', 'P', 'DIV', 'LI'].includes(child.nodeName)) {
                            text += '\n';
                        }
                        // 画像の場合はPlaceHolderに置き換え
                        if (['IMG'].includes(child.nodeName)) {
                            text += '[IMAGE_PLACEHOLDER]';
                        }
                        // 子要素を再帰的に処理
                        text += elementToText(child);

                        // LI要素の後に改行を追加
                        if (child.nodeName === 'LI') {
                            text += '\n';
                        }
                    }
                });
                return text;
            }

            // body内の要素をテキストに変換
            return elementToText(doc.body).trim();
        },
        ensureDivWrapping() {
            const contentEditableDiv = this.$refs.blogContent;
            this.updateContent();
            console.clear();
            console.debug(contentEditableDiv.innerHTML);;
            console.debug(this.blogContentText);
            console.debug(this.blogContentLineBreakCount);
        },
        updateContent() {
            this.blogContent = this.$refs.blogContent.innerHTML;
            this.blogContentText = this.getTextfromHtml(this.blogContent);
            this.blogContentTextCount = this.blogContentText.replace(new RegExp("\[IMAGE_PLACEHOLDER\]", 'g'), '').replace(/\n/g, '').length;
            this.blogContentLineBreakCount = (this.blogContentText.match(/\n/g) || []).length;
        },
        handleImageUpload(event) {
            const files = event.target.files;
            if (files.length + this.images.length > 4) {
                alert('画像は最大4枚までです。');
                return;
            }

            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                const fileId = this.nextFileId++;
                reader.onload = (e) => {
                    this.insertImageAtCursor(e.target.result, fileId);
                    this.images.push(e.target.result);
                    this.localImageFiles.push({ file: files[i], id: fileId }); // 追加
                };
                reader.readAsDataURL(files[i]);
            }
        },
        insertImageAtCursor(imageUrl, fileId) {
            const contentEditableDiv = this.$refs.blogContent;
            if (!contentEditableDiv) {
                console.error('Content editable div not found');
                return;
            }

            var blogContentElement = document.getElementById('blogContent');
            if (blogContentElement) {
                // 現在フォーカスされているエレメントを取得
                var focusedElement = document.activeElement;

                // フォーカスされているエレメントが 'blogContent' でない、または 'blogContent' の子孫でない場合
                if (document.activeElement == null || focusedElement !== blogContentElement && !blogContentElement.contains(focusedElement)) {
                    blogContentElement.focus();
                    var range1 = document.createRange();
                    var selection = window.getSelection();
                    range1.selectNodeContents(blogContentElement);
                    range1.collapse(false); // falseで末尾にキャレットを設定
                    selection.removeAllRanges();
                    selection.addRange(range1);
                }
            }

            const range = window.getSelection().getRangeAt(0);
            const img = document.createElement('img');
            img.src = imageUrl;
            img.alt = '画像';
            img.style.maxWidth = '100px';
            img.style.maxHeight = '100px';
            img.style.objectFit = 'cover';
            img.setAttribute('data-file-id', fileId); // カスタム属性を追加

            range.insertNode(img);
            this.updateContent();
        },
        async getPresignedUrl({ userId, postId, extension }) {
            try {
                //throw new Error('some error')
                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/presigned-url`;
                const queryParams = new URLSearchParams({
                    postId,
                    extension: extension.toLowerCase(),
                });
                const response = await fetch(`${url}?${queryParams}`, { method: 'GET' });
                const jsonRes = await response.json();

                if (response.ok) {
                    return jsonRes;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('署名付きポストURLの取得失敗', error);
                throw error;
            }
        },
        async uploadMediaFiles({ presignedUrl, form }) {
            try {
                const response = await fetch(presignedUrl, {
                    method: 'POST',
                    body: form,
                });
                if (response.ok) {
                    return true;
                } else {
                    throw new Error(response.error || response.message);
                }
            } catch (error) {
                console.error('S3へのアップロード失敗', error);
                this.errorSavePost = 'アップロードに失敗しました';
            }
        },
        async updateLocalImageFilesOrder() {
            const contentEditableDiv = this.$refs.blogContent;
            const imgElements = contentEditableDiv.getElementsByTagName('img');
            const newLocalImageFiles = [];

            for (let imgElement of imgElements) {
                const fileId = imgElement.getAttribute('data-file-id');
                const matchingFile = this.localImageFiles.find(fileObj => fileObj.id == fileId);

                if (matchingFile) {
                    newLocalImageFiles.push(matchingFile);
                } else {
                    // 新しく追加された画像はlocalImageFilesに追加されていないので、srcを用いて追加
                    const src = imgElement.src;
                    const extension = src.split('.').pop().toLowerCase();
                    const imageBlob = await fetch(src).then(res => res.blob());
                    const newFile = new File([imageBlob], `image_${imgElements.length + 1}.${extension}`, { type: `image/${extension}` });
                    const newFileId = this.nextFileId++;
                    newLocalImageFiles.push({ file: newFile, id: newFileId });
                    imgElement.setAttribute('data-file-id', newFileId);
                }
            }

            this.localImageFiles = newLocalImageFiles;
        },
        clearMessages() {
            this.errorGetStyle = null;

            this.saveSuccess = null;
            this.errorSavePost = null;

            this.deleteSuccess = null;
            this.errorDeletePost = null;
        }
    }
};
</script>

<style>
.image-placeholder {
    width: 100%;
    padding-bottom: 75%;
    /* 4:3の縦横比を維持 */
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
}

.image-placeholder::before {
    color: #aaa;
    font-size: 1.5rem;
}

.image-container {
    width: 100%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
</style>
