<template>
  <div class="container">
    <h2 class="text-center mb-4">ブログ管理</h2>
    <div v-if="errorSavePost" class="alert alert-danger mt-3" role="alert">
      {{ errorSavePost }}
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <router-link :to="`/blog`" class="btn btn-outline-primary">新規登録</router-link>
    </div>
  </div>
  <div class="container mt-5">
    <h2 class="text-center mb-4">ブログ一覧</h2>
    <div v-if="errorGetPosts" class="alert alert-danger mt-3" role="alert">
      {{ errorGetPosts }}
    </div>
    <div v-if="deleteSuccess" class="alert alert-success mt-3" role="alert">
      {{ deleteSuccess }}
    </div>
    <div>
      <p v-if="!getting && (!blogs || blogs.length === 0)">ブログがありません</p>
      <p v-if="getting">読み込み中...</p>
      <div v-for="blog in blogs" :key="blog.id">
        <table class="table border mb-4 text-start">
          <tr>
            <td rowspan="6" class="w-25 text-center">
              <span v-if="blog.last_error != null" class="text-danger">エラーあり</span>
              <img class="img-fluid" :src="blog.image1_url"><br />
              <router-link :to="`/blog/${blog.id}`" class="btn btn-outline-primary">編集</router-link>
            </td>
            <td>
              <label class="w-25 text-center">タイトル</label>
              {{ blog.title }}
            </td>
          </tr>
          <tr>
            <td>
              <label class="w-25 text-center">カテゴリ</label>
              {{ blog.category }}
            </td>
          </tr>
          <tr>
            <td>
              <label class="w-25 text-center">クーポン</label>
              {{ blog.coupon }}
            </td>
          </tr>
          <tr>
            <td>
              <label class="w-25 text-center">投稿者</label>
              {{ shopDataJson.stylists2.filter((stylist2) => stylist2.salonboard_id === blog.contributor_id).map((item) => item.name).join(', ') }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getShopInfo } from '../commonMethods.js';

export default {
  data() {
    return {
      caption: '',
      media: null,
      errorSavePost: null,
      saveSuccess: null,
      saving: false,

      shopDataJson: null,

      blogs: null,
      maxPostCounts: 20,
      getting: null,
      errorGetPosts: null,

      deleting: false,
      postToDelete: null,
      errorDeletePost: null,
      deleteSuccess: null,

      mediaBucketUrl: process.env.VUE_APP_MEDIA_BUCKET_URL,
      validVideoFileTypes: ['video/mp4', 'video/quicktime'],
      validImageFileTypes: ['image/jpeg'],
    };
  },
  computed: {
    ...mapGetters([
      'sessionUser'
    ]),
  },
  async mounted() {
    this.shopDataJson = await getShopInfo(this.sessionUser);
    this.blogs = await this.getBlogs();
    this.getting = false;
  },
  methods: {
    async getBlogs() {
      this.getting = true;
      this.errorGetPosts = null;

      try {
        const userId = this.sessionUser.idToken?.payload?.sub;
        const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/blogs`;
        const response = await fetch(url, { method: 'GET' });

        const jsonRes = await response.json();

        if (response.ok) {
          return jsonRes.blogs;
        } else {
          throw new Error(jsonRes.error || jsonRes.message);
        }
      } catch (error) {
        console.error('全ブログデータ取得失敗', error);
        this.errorGetPosts = '全ブログデータ取得に失敗しました';
      } finally {
        //this.getting = false;
      }
    },
  }
};
</script>

<style scoped></style>