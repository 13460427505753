<template>
    <div class="text-start">

        <h1>Salon Helper 使い方</h1>
        <h2 class="mt-5">投稿内容の登録</h2>
        <ol>
            <li>投稿したいテキストとキャプションと画像/動画を登録します。</li>
            <li>登録済みデータの一覧も確認できます。</li>
            <li>データの削除もこの画面で行えます。</li>
        </ol>
        <img class="img-fluid rounded mx-auto d-block border" src="/pic1.png" style="width: 80%">
        <h2 class="mt-5">TikTokアカウント登録</h2>
        <ol>
            <li>TikTokのアカウントとの紐づけをします。</li>
            <li>画面のボタンをクリックしてTikTokにログインして簡単な操作をするだけ！</li>
        </ol>
        <img class="img-fluid rounded mx-auto d-block border" src="/pic3.png" style="width: 80%">
        <h2 class="mt-5">インスタアカウント登録</h2>
        <ol>
            <li>インスタのアカウントIDをトークンを入力するだけ！</li>
        </ol>
        <img class="img-fluid rounded mx-auto d-block border" src="/pic2.png" style="width: 80%">
        <h2 class="mt-5">この作業だけで、自動投稿が開始します</h2>
        <br>
        <a href="/" class="mt-5 center text-right">トップへ</a>
    </div>

</template>