<template>
    <div class="text-start">

        <h1>プライバシーポリシー</h1>
        <p>本サイトは、取得した個人情報の取り扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドラインなどの指針、その他個人情報保護に関する関係法令を遵守します。</p>
        <h2>１．個人情報の安全管理</h2>
        <p>本サイトは、個人情報の保護に関して、組織的、物理的、人的、技術的に適切な対策を実施し、本サイトの取り扱う個人情報の漏洩、滅失又は棄損の防止、その他個人情報の安全管理のために必要かつ適切な措置を講ずるものとします。
        </p>
        <h2>２．個人情報の取得等の遵守事項</h2>
        <p>本サイトにおける個人情報の取得、利用、提供については、以下の事項を遵守します。</p>
        <h3>個人情報の取得</h3>
        <p>本サイトは、本サイトの運営に必要な範囲で、本サイトの一般利用者（以下、ユーザーといいます）又は本サイトに広告掲載を行う者（以下、掲載主といいます）から、ユーザーまたは掲載主に関わる個人情報を取得することがあります。
        </p>
        <h3>個人情報の利用目的</h3>
        <p>本サイトは取得した個人情報について、法令に定める場合又は本人の同意を得た場合を除き、いかに定める利用目的の達成に必要な範囲を超えて利用することはありません。</p>
        <p>①本サイトの運営、維持、管理のため</p>
        <p>②本サイトを通じたサービスの提供および紹介のため</p>
        <p>③利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため</p>
        <p>④上記の利用目的に付随する目的</p>
        <h2>３．取得する個人情報</h2>
        <p>本サイトは、前項利用目的のために以下の情報を取得します。</p>
        <p>①不正アクセスの接続元を特定するために、HTTPプロトコルで技術的に取得できる情報全て（接続元IPアドレス、利用日時、参照元IPアドレス等）</p>
        <h2>４．Cookieを利用した広告配信</h2>
        <p>本サイトは、Google及びGoogleのパートナー（第三者配信事業者）の提供する広告を設置しております。その広告配信にはCookieを使用し、当サイトやその他のサイトへの過去のアクセス情報に基づいて広告を配信します。
        </p>
        <p>Google が広告 Cookie を使用することにより、当サイトや他のサイトにアクセスした際の情報に基づいて、Google やそのパートナーが適切な広告を表示しています。</p>
        <p>サイト利用者はGoogleアカウントの広告設定ページ（https://adssettings.google.com/u/0/authenticated）で、パーソナライズ広告を無効にできます。また
            aboutads.info
            のページにアクセスして頂き、パーソナライズ広告掲載に使用される第三者配信事業者のCookieを無効にできます。</p>
        <p>その他、Googleの広告におけるCookieの取り扱い詳細については、Googleのポリシーと規約ページ（https://policies.google.com/technologies/ads）をご覧ください。
        </p>
        <h2>５．利用目的の変更</h2>
        <p>本サイトは、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。</p>
        <p>利用目的の変更を行った場合には、変更後の目的について、本ウェブサイト上に公表するものとします。</p>
        <h2>６．個人情報の提供等</h2>
        <p>本サイトは、法令で定める場合を除き、本人の同意に基づき取得した個人情報を、本人の事前の同意なく第三者に提供することはありません。なお、本人の求めによる個人情報の開示、訂正、追加もしくは削除又は利用目的の通知については、法令に従いこれを行うとともに、ご意見、ご相談に関して適切に対応します。
        </p>
        <h2>７．個人情報の第三者提供</h2>
        <p>本サイトは、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。</p>
        <p>①人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</p>
        <p>②公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</p>
        <p>③国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</p>
        <p>④予め次の事項を告知あるいは公表し、かつ本サイトが個人情報保護委員会に届出をしたとき</p>
        <p>
        <ol>
            <li>利用目的に第三者への提供を含むこと</li>
            <li>第三者に提供されるデータの項目</li>
            <li>第三者への提供の手段または方法</li>
            <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
            <li>本人の求めを受け付ける方法</li>
        </ol>
        </p>
        <p>
            ⑤前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
        <ol>
            <li>本サイトが利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
            <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
            <li>個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
            </li>
        </ol>
        </p>
        <h2>６．プライバシーポリシーの変更</h2>
        <p>本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。</p>
        <p>本サイトが別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。</p>
    </div>

</template>