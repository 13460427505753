<template>
    <span v-if="extractSpecialCharacters.length > 0" class="text-danger sm-2">
        使用不可
        <span v-for="(char, index) in extractSpecialCharacters" :key="index">
            {{ char }}<span v-if="index < extractSpecialCharacters.length - 1">, </span>
        </span>
    </span>
</template>
  
    <script>
  export default {
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    computed: {
        extractSpecialCharacters() {
          const specialCharacterPattern = new RegExp(/[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]/, 'g');

          //const specialCharacterPattern = /[\u203C-\u3299\u1F000-\u1F6FF\u1F700-\u1F77F\u1F780-\u1F7FF\u1F800-\u1F8FF\u1F900-\u1F9FF\u1FA00-\u1FA6F\u1FA70-\u1FAFF\u2600-\u26FF\u2700-\u27BF\u2B50-\u2B55\u231A-\u231B\u23E9-\u23EF\u23F0\u23F3\u25FD-\u25FE\u2934-\u2935\u2B05-\u2B07\u3030\u303D\u3297\u3299\uD83C\uD000-\uDFFF\uD83D\uD000-\uDFFF\uD83E\uD000-\uDFFF]/g;
          var ret = this.value.match(specialCharacterPattern) || [];
            return ret;
        },
    },
  };
  </script>

  <style scoped>
  .text-danger {
    color: red;
  }
  </style>