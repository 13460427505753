<template>
    <span>
      <span v-if="this.value === null || this.value === ''" class="text-danger sm-2">入力必須</span>
    </span>
  </template>
  
    <script>
  export default {
    props: {
      value: {
        type: String,
        required: true,
      },
    },
  };
  </script>

  <style scoped>
  .text-danger {
    color: red;
  }
  </style>