<template>
    <span>
      {{ currentLength }}/{{ maxLength }}
      <span v-if="currentLength > maxLength" class="text-danger sm-2">文字数オーバー</span>
    </span>
  </template>
  
    <script>
  export default {
    props: {
      value: {
        type: String,
        required: true,
      },
      maxLength: {
        type: Number,
        required: true,
      },
    },
    computed: {
      currentLength() {
        return this.value.length;
      },
    },
  };
  </script>

  <style scoped>
  .text-danger {
    color: red;
  }
  </style>