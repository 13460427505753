<template>

    <div class="container text-start">
        <h2 class="text-center mb-4">スタイル登録</h2>
        <!-- <p v-if="getting">読み込み中...</p> -->
        <div v-if="style">
            <div v-if="style.last_error != null" class="alert alert-danger" role="alert">
                SALON BOARD投稿時にエラーが発生しました。<br />
                <pre>{{ style.last_error }}</pre>
            </div>
            <div class="card mb-4">
                <div class="card-header">スタイル情報</div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        スタイル登録形式
                        <span v-for="type in registrationFormatTypes" :key="type">
                            <input type="radio" :id="type" :value="type" v-model="style.registration_format">
                            <label :for="type" class="me-2">{{ type }}</label>
                        </span>
                        <Required :value="style.registration_format" />

                    </li>
                    <li class="list-group-item">
                        スタイル登録<br />
                        <div class="row">
                            <div class="col-4">
                                FRONT
                                <br />
                                <input type="file" id="fileInput1" @change="onFileChange" accept="image/*"
                                    style="display: none;">
                                <label for="fileInput1" class="btn btn-primary btn-sm">ファイルを選択</label>
                                <div v-if="!this.localImageFile1 && this.remoteImagePath1 != ''" class="image-container">
                                    <img :src="remoteImagePath1" alt="Selected Image" class="img-fluid mt-3">
                                    <label class="btn btn-primary btn-sm" @click="fileDelete(1)">削除</label>
                                </div>
                                <div v-if="this.localImageFile1" class="image-container">
                                    <img :src="this.localImageData1" alt="Selected Image" class="img-fluid mt-3">
                                    <label class="btn btn-primary btn-sm" @click="fileDelete(1)">削除</label>
                                </div>
                            </div>
                            <div class="col-4">
                                <select id="style_image2_type" v-model="style.style_image2_type">
                                    <option v-for="item in styleTypes" :key="item" :value="item">{{ item }}</option>
                                </select>
                                <input type="file" id="fileInput2" @change="onFileChange" accept="image/*"
                                    style="display: none;">
                                <label for="fileInput2" class="btn btn-primary btn-sm">ファイルを選択</label>
                                <div v-if="!this.localImageFile2 && this.remoteImagePath2 != ''" class="image-container">
                                    <img :src="remoteImagePath2" alt="Selected Image" class="img-fluid mt-3">
                                    <label class="btn btn-primary btn-sm" @click="fileDelete(2)">削除</label>
                                </div>
                                <div v-if="this.localImageFile2" class="image-container">
                                    <img :src="this.localImageData2" alt="Selected Image" class="img-fluid mt-3">
                                    <label class="btn btn-primary btn-sm" @click="fileDelete(2)">削除</label>
                                </div>
                            </div>
                            <div class="col-4">
                                <select id="style_image3_type" v-model="style.style_image3_type">
                                    <option v-for="item in styleTypes" :key="item" :value="item">{{ item }}</option>
                                </select>
                                <input type="file" id="fileInput3" @change="onFileChange" accept="image/*"
                                    style="display: none;">
                                <label for="fileInput3" class="btn btn-primary btn-sm">ファイルを選択</label>
                                <div v-if="!this.localImageFile3 && this.remoteImagePath3 != ''" class="image-container">
                                    <img :src="remoteImagePath3" alt="Selected Image" class="img-fluid mt-3">
                                    <label class="btn btn-primary btn-sm" @click="fileDelete(3)">削除</label>
                                </div>
                                <div v-if="this.localImageFile3" class="image-container">
                                    <img :src="this.localImageData3" alt="Selected Image" class="img-fluid mt-3">
                                    <label class="btn btn-primary btn-sm" @click="fileDelete(3)">削除</label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="card mb-4">
                <div class="card-header">スタイリストコメント</div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <label style="width: 20%;">スタイリスト名</label>
                        <select id="color" v-model="style.stylist_id">
                            <option v-for="stylistName in stylistNameList" :key="stylistName.name" :value="stylistName.salonboard_id">
                                {{ stylistName.name }}
                            </option>
                        </select>
                        <Required :value="style.stylist_id" />
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-2">コメント </div>
                            <div class="col-10">
                                　<textarea type="text-area" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                    id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.comment" />
                                    <CharCount :value="style.comment" :maxLength="maxLength.comment" />
                                    <Required :value="style.comment" />
                                    <Prohibited :value="style.comment" />

                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!--
            <div class="card mb-4">
                <div class="card-header">スタイリング・アレンジポイント</div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <label style="width: 20%;">ポイント</label>
                        <input type="textarea" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="style.point">
                        {{ style.point.length }}/120
                    </li>
                </ul>
            </div>
            -->

            <div class="card mb-4">
                <div class="card-header">スタイル</div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <label style="width: 20%;">スタイル名</label>
                        <input type="text" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="style.style_name" />
                            <CharCount :value="style.style_name" :maxLength="maxLength.styleName" />
                            <Required :value="style.style_name" />
                            <Prohibited :value="style.style_name" />

                    </li>
                    <li class="list-group-item">
                        <label style="width: 20%;">カテゴリ</label>
                        <span v-for="type in categoryTypes" :key="type">
                            <input type="radio" :id="type" :value="type" v-model="style.category">
                            <label :for="type" class="me-2">{{ type }}</label>
                        </span>
                        <Required :value="style.category" />
                    </li>
                    <li class="list-group-item">
                        <label for="length" style="width: 20%;">長さ</label>
                        <select id="length" v-model="style.length">
                            <option v-for="length in lengthTypes" :key="length" :value="length">{{ length }}</option>
                        </select>
                        <Required :value="style.length" />
                    </li>
                    <!--
                    <li class="list-group-item">
                        <label for="color" style="width: 20%;">カラー</label>
                        <select id="color" v-model="style.color">
                            <option v-for="color in colorTypes" :key="color" :value="color">{{ color }}</option>
                        </select>
                    </li>
                    -->
                    <!--
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-2">
                                イメージ
                            </div>
                            <div class="col-10 ">
                                <span v-for="type in imageTypes" :key="type">
                                    <input type="radio" :id="type" :value="type" v-model="style.image">
                                    <label :for="type" class="me-2">{{ type }}</label>
                                </span>
                            </div>
                        </div>
                    </li>
                    -->
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-2">メニュー内容</div>
                            <div class="col-10">
                                <label>
                                    <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                        id="styleRegistFormat0" class="styleRegistFormatRadio"
                                        v-model="style.menu_perm">
                                    パーマ　
                                </label>
                                <label>
                                    <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                        id="styleRegistFormat0" class="styleRegistFormatRadio"
                                        v-model="style.menu_straight_perm">
                                    ストレートパーマ・縮毛矯正　
                                </label>
                                <label>
                                    <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                        id="styleRegistFormat0" class="styleRegistFormatRadio"
                                        v-model="style.menu_extensions">
                                    エクステ　
                                </label>
                                <label>
                                    <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                        id="styleRegistFormat0" class="styleRegistFormatRadio"
                                        v-model="style.menu_bleach">
                                    ブリーチ　
                                </label>
                                <br />
                                <label>
                                    <textarea type="text-area" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                        id="styleRegistFormat0" class="styleRegistFormatRadio"
                                        v-model="style.menu_remark"></textarea>
                                        <CharCount :value="style.menu_remark" :maxLength="maxLength.menu_remark" />
                                        <Required :value="style.menu_remark" />
                                        <Prohibited :value="style.menu_remark" />

                                </label>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <label for="length" style="width: 20%;">クーポン</label>
                        <select id="length" v-model="style.coupon">
                            <option v-for="item in couponList" :key="item" :value="item">{{ item }}</option>
                        </select>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 20%;">ハッシュタグ</label>
                        <input type="text" name="frmStyleEditStyleInfoDto.styleRegistFormat" id="styleRegistFormat0"
                            class="styleRegistFormatRadio" v-model="style.hashtag" />
                            <Prohibited :value="style.hashtag" />

                    </li>

                </ul>
            </div>
            
            <!--
            <div class="card mb-4">
                <div class="card-header">おすすめタイプ</div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <label style="width: 10%;">髪量</label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.hair_amount_few">
                            少ない　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.hair_amount_normal">
                            普通　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.hair_amount_many">
                            多い　
                        </label>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">髪質</label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.hair_type_soft">
                            柔らかい　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.hair_type_normal">
                            普通　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.hair_type_hard">
                            硬い　
                        </label>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">太さ</label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.hair_thickness_thin">
                            細い　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.hair_thickness_normal">
                            普通　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.hair_thickness_thick">
                            多い　
                        </label>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">クセ</label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.hair_habit_none">
                            なし　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.hair_habit_little">
                            少し　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.hair_habit_strong">
                            強い　
                        </label>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">顔型</label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.face_shape_round">
                            丸型　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.face_shape_inverted_triangle">
                            逆三角　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio" v-model="style.face_shape_egg">
                            卵型　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.face_shape_base">
                            ベース　
                        </label>
                        <label>
                            <input type="checkbox" name="frmStyleEditStyleInfoDto.styleRegistFormat"
                                id="styleRegistFormat0" class="styleRegistFormatRadio"
                                v-model="style.face_shape_square">
                            四角　
                        </label>
                    </li>
                </ul>
            </div>
            -->

            <div class="card mb-4">
                <div class="card-header">スタイルのモデル情報</div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <label style="width: 10%;">髪量</label>
                        <span v-for="type in modelHairAmountTypes" :key="type">
                            <input type="radio" :id="'髪量' + type" :value="type" v-model="style.model_hair_amount">
                            <label :for="'髪量' + type" class="me-2">{{ type }}</label>
                        </span>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">髪質</label>
                        <span v-for="type in modelHairTypes" :key="type">
                            <input type="radio" :id="'髪質' + type" :value="type" v-model="style.model_hair_type">
                            <label :for="'髪質' + type" class="me-2">{{ type }}</label>
                        </span>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">太さ</label>
                        <span v-for="type in modelHairThicknessTypes" :key="type">
                            <input type="radio" :id="'太さ' + type" :value="type" v-model="style.model_hair_thickness">
                            <label :for="'太さ' + type" class="me-2">{{ type }}</label>
                        </span>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">クセ</label>
                        <span v-for="type in modelHairHabitTypes" :key="type">
                            <input type="radio" :id="'クセ' + type" :value="type" v-model="style.model_hair_habit">
                            <label :for="'クセ' + type" class="me-2">{{ type }}</label>
                        </span>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">顔型</label>
                        <span v-for="type in modelFaceShapeTypes" :key="type">
                            <input type="radio" :id="'顔型' + type" :value="type" v-model="style.model_face_shape">
                            <label :for="'顔型' + type" class="me-2">{{ type }}</label>
                        </span>
                    </li>
                    <li class="list-group-item">
                        <label style="width: 10%;">年代</label>
                        <span v-for="type in modelAgeTypes" :key="type">
                            <input type="radio" :id="'年代' + type" :value="type" v-model="style.model_age">
                            <label :for="'年代' + type" class="me-2">{{ type }}</label>
                        </span>
                    </li>
                    <li class="list-group-item">
                        <label for="length" style="width: 20%;">ヘアカタログ</label>
                        <select id="length" v-model="style.hair_catalog">
                            <option v-for="item in hairCatalogList" :key="item" :value="item">{{ item }}</option>
                        </select>
                    </li>

                </ul>
            </div>

            <div class="row">
                <div class="col-2">
                    <form @submit.prevent="submitSaveStyleForm" class="needs-validation">
                        <button type="submit" class="btn btn-primary w-100"
                            :disabled="saving || (posts && posts.length >= maxPostCounts)">
                            {{ saving ? '登録中...' : '登録' }}
                        </button>
                    </form>
                </div>
                <div class="col-1">
                    　
                </div>
                <div class="col-2">
                    <form @submit.prevent="submitDeleteStyleForm" class="needs-validation">
                        <button type="submit"
                            :disabled="deleting" class="btn btn-danger w-100">
                            {{ deleting ? '削除中' : '削除' }}
                        </button>
                    </form>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import CharCount from '../components/CharCount.vue'; 
import Required from '../components/Required.vue'; 
import Prohibited from '../components/Prohibited.vue'; 
import { mapGetters } from 'vuex';
import { getShopInfo } from '../commonMethods.js';

export default {
    components: {
        CharCount,
        Required,
        Prohibited,
    },
    data() {
        return {
            styleId: null,
            style: null,
            caption: '',
            media: null,
            errorSavePost: null,
            saveSuccess: null,
            saving: false,
            deleteing: false,

            maxLength: {
                styleName: 27,  // 30文字-自動投稿識別文字列分3文字
                comment: 120,
                menu_remark: 50,
            },

            posts: null,
            maxPostCounts: 20,
            getting: null,
            errorGetStyle: null,

            deleting: false,
            postToDelete: null,
            errorDeletePost: null,
            deleteSuccess: null,

            //リモートにイメージがある場合はそのパス名が入る
            remoteImagePath1: null,
            remoteImagePath2: null,
            remoteImagePath3: null,

            //選択されたローカスのfile
            localImageFile1: null,
            localImageFile2: null,
            localImageFile3: null,

            //ローカル画像のsrc属性に渡すデータ
            localImageData1: null,
            localImageData2: null,
            localImageData3: null,

            mediaBucketUrl: process.env.VUE_APP_MEDIA_BUCKET_URL,
            validVideoFileTypes: ['video/mp4', 'video/quicktime'],
            validImageFileTypes: ['image/jpeg'],
            registrationFormatTypes: ['画像', '動画'],
            styleTypes: ['FRONT', 'SIDE', 'BACK', 'ARRANGE', 'BEFORE', 'FASHION'],
            categoryTypes: ['レディース', 'メンズ'],
            lengthTypes: ['', 'ベリーショート', 'ショート', 'ミディアム', 'セミロング', 'ロング', 'ヘアセット', 'ミセス'],
            colorTypes: ['', 'ブラウン・ベージュ系', 'イエロー・オレンジ系', 'レッド・ピンク系', 'アッシュ・ブラック系', 'その他カラー'],
            imageTypes: ['ナチュラル', 'オフィス・コンサバ', 'モテ・愛され', 'ギャル', 'カジュアル・ストリート', 'ティーンズ・ガーリー'],
            modelHairAmountTypes: ['設定しない', '少ない', '普通', '多い'],
            modelHairTypes: ['設定しない', '柔かい', '普通', '硬い'],
            modelHairThicknessTypes: ['設定しない', '細い', '普通', '太い'],
            modelHairHabitTypes: ['設定しない', 'なし', '少し', '強い'],
            modelFaceShapeTypes: ['設定しない', '逆三角', '丸型', 'ベース', '卵型', '面長', '四角'],
            modelAgeTypes: ['設定しない', 'キッズ', '10代', '20代', '30代', '40代', '50代', '60代以上'],
            stylistNameList: [],
            stylistNameList2: [],
            hairCatalogList: [],
            couponList : [],
        };
    },
    computed: {
        ...mapGetters([
            'sessionUser'
        ]),

    },
    async mounted() {
        var shopInfoJson = await getShopInfo(this.sessionUser);
        if (shopInfoJson != null) {
            this.couponList = shopInfoJson.coupons;
            this.stylistNameList = shopInfoJson.stylists;
            this.hairCatalogList = shopInfoJson.hairCatalogs;
        }
        if (this.styleId) {
            this.style = await this.getStyle();
        } else {
            this.style = {
                id: 0,
                registration_format: "",
                style_image1_url: "",
                style_image2_type: "",
                style_image2_url: "",
                style_image3_type: "",
                style_image3_url: "",
                stylist_id: "",
                comment: "",
                point: "",
                style_name: "",
                category: "",
                length: "",
                color: "",
                image: "",
                menu_perm: false,
                menu_straight_perm: false,
                menu_extensions: false,
                menu_bleach: false,
                menu_remark: "",
                coupon: "",
                hashtag: "",
                hair_amount_few: true,
                hair_amount_normal: true,
                hair_amount_many: true,
                hair_type_soft: true,
                hair_type_normal: true,
                hair_type_hard: true,
                hair_thickness_thin: true,
                hair_thickness_normal: true,
                hair_thickness_thick: true,
                hair_habit_none: true,
                hair_habit_little: true,
                hair_habit_strong: true,
                face_shape_round: true,
                face_shape_inverted_triangle: true,
                face_shape_egg: true,
                face_shape_base: true,
                face_shape_square: true,
                model_hair_amount: "",
                model_hair_type: "",
                model_hair_thickness: "",
                model_hair_habit: "",
                model_face_shape: "",
                model_age: "",
                hair_catalog: "",
                last_error: null
            };
        }
        this.remoteImagePath1 = this.style.style_image1_url;
        this.remoteImagePath2 = this.style.style_image2_url;
        this.remoteImagePath3 = this.style.style_image3_url;

    },
    created() {
        this.styleId = this.$route.params.styleId;
    },
    methods: {
        // async getShopInfo() {
        //     try {
        //         const userId = this.sessionUser.idToken?.payload?.sub;
        //         const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/shop`;
        //         const response = await fetch(url, { method: 'GET' });

        //         const jsonRes = await response.json();

        //         if (response.ok) {
        //             return jsonRes;
        //         } else {
        //             throw new Error(jsonRes.error || jsonRes.message);
        //         }
        //     } catch (error) {
        //         console.error('店舗データ取得失敗', error);
        //         this.errorGetStyle = '店舗データ取得に失敗しました';
        //     } finally {
        //         this.getting = false;
        //     }
        // },
        onFileChange(event) {
            const file = event.target.files[0];
            const elementId = event.target.id;
            if (file && file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (elementId === 'fileInput1') {
                        this.localImageData1 = e.target.result;
                        this.localImageFile1 = file;
                    } else if (elementId === 'fileInput2') {
                        this.localImageData2 = e.target.result;
                        this.localImageFile2 = file;
                    } else if (elementId === 'fileInput3') {
                        this.localImageData3 = e.target.result;
                        this.localImageFile3 = file;
                    }
                };
                reader.readAsDataURL(file);
            } else {
                this.tmpImageData1 = null; // 無効なファイルの場合はクリア
            }
        },
        fileDelete(index) {
            if (index == 1) {
                this.localImageFile1 = null;
                this.remoteImagePath1 = "";
                this.style.style_image1_url = "";
            } else if (index == 2) {
                this.localImageFile2 = null;
                this.remoteImagePath2 = "";
                this.style.style_image2_url = "";
            } else if (index == 3) {
                this.localImageFile3 = null;
                this.remoteImagePath3 = "";
                this.style.style_image3_url = "";
            }
        },
        async getStyle() {
            this.getting = true;
            this.errorGetStyle = null;

            try {
                const userId = this.sessionUser.idToken?.payload?.sub;
                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/style/${this.styleId}`;
                const response = await fetch(url, { method: 'GET' });

                const jsonRes = await response.json();

                if (response.ok) {
                    return jsonRes.style;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('スタイルデータ取得失敗', error);
                this.errorGetStyle = 'スタイルデータ取得に失敗しました';
            } finally {
                this.getting = false;
            }
        },

        async submitSaveStyleForm() {
            this.clearMessages();
            this.saving = true;
            let styleId;

            try {
                // 投稿IDを取得するため先に投稿のメタデータをDBに保存
                const userId = this.sessionUser?.idToken?.payload?.sub;
                this.style.user_id = userId;
                const styleMetadata = await this.saveStyleMetadata({
                    userId: userId,
                    style: this.style,
                });

                // 画像をS3にアップロードするための署名付きURLを取得
                styleId = styleMetadata.id;
                this.style.id = styleId;

                // 画像をS3にアップロード
                var extension1;
                var extension2;
                var extension3;
                var uploaded = true;
                if (this.localImageFile1) {
                    const form = new FormData();
                    extension1 = this.localImageFile1.name.split('.').pop().toLowerCase();
                    const presigned1 = await this.getPresignedUrl({
                        userId,
                        postId: "s" + styleId + "_1",
                        extension: extension1
                    });
                    Object.keys(presigned1.fields).forEach(key => {
                        form.append(key, presigned1.fields[key]);
                    })
                    form.append('file', this.localImageFile1);
                    uploaded &= await this.uploadMediaFiles({
                        presignedUrl: presigned1.url,
                        form,
                    });
                }
                if (this.localImageFile2) {
                    const form = new FormData();
                    extension2 = this.localImageFile2.name.split('.').pop().toLowerCase();
                    const presigned2 = await this.getPresignedUrl({
                        userId,
                        postId: "s" + styleId + "_2",
                        extension: extension2
                    });
                    Object.keys(presigned2.fields).forEach(key => {
                        form.append(key, presigned2.fields[key]);
                    })
                    form.append('file', this.localImageFile2);
                    uploaded &= await this.uploadMediaFiles({
                        presignedUrl: presigned2.url,
                        form,
                    });
                }
                if (this.localImageFile3) {
                    const form = new FormData();
                    extension3 = this.localImageFile3.name.split('.').pop().toLowerCase();
                    const presigned3 = await this.getPresignedUrl({
                        userId,
                        postId: "s" + styleId + "_3",
                        extension: extension3
                    });
                    Object.keys(presigned3.fields).forEach(key => {
                        form.append(key, presigned3.fields[key]);
                    })
                    form.append('file', this.localImageFile3);
                    uploaded &= await this.uploadMediaFiles({
                        presignedUrl: presigned3.url,
                        form,
                    });
                }

                //保存先URLを保存
                if (this.localImageFile1) {
                    this.style.style_image1_url = this.localImageFile1 == "" ? "" : this.mediaBucketUrl + '/' + this.sessionUser?.idToken?.payload?.sub + '/s' + styleId + '_1.' + extension1;
                }
                if (this.localImageFile2) {
                    this.style.style_image2_url = this.localImageFile2 == "" ? "" : this.mediaBucketUrl + '/' + this.sessionUser?.idToken?.payload?.sub + '/s' + styleId + '_2.' + extension2;
                }
                if (this.localImageFile3) {
                    this.style.style_image3_url = this.localImageFile3 == "" ? "" : this.mediaBucketUrl + '/' + this.sessionUser?.idToken?.payload?.sub + '/s' + styleId + '_3.' + extension3;
                }
                await this.saveStyleMetadata({
                    userId: userId,
                    style: this.style,
                });

                if (uploaded) {
                    this.saveSuccess = '登録しました';
                }
                this.$router.push('/styles');
            } catch (error) {
                console.error('登録失敗', error);
                this.errorSavePost = '登録に失敗しました。再度お試しください';
            } finally {
                this.saving = false;
            }
        },
        async submitDeleteStyleForm() {
            this.clearMessages();
            this.deleting = true;

            try {
                const userId = this.sessionUser?.idToken?.payload?.sub;
                await this.deleteStyle({
                    userId: userId,
                    style: this.style,
                });
                this.$router.push('/styles');
            } catch (error) {
                console.error('削除失敗', error);
                this.errorSavePost = '削除に失敗しました。再度お試しください';
            } finally {
                this.saving = false;
            }
        },
        getValidFileType(file) {
            try {
                if (this.validVideoFileTypes.includes(file.type)) {
                    return 'video';
                }
                if (this.validImageFileTypes.includes(file.type)) {
                    return 'image';
                }
                return;
            } catch (error) {
                console.log('ファイルタイプ検証失敗', error);
            }
        },

        isValidFileSize(file, fileType) {
            const videoMaxSizeInByte = 1_073_741_824; // 1GB
            const imageMaxSizeInByte = 8_388_608; // 8MB

            if (fileType === 'video') {
                return file.size <= videoMaxSizeInByte;
            }
            if (fileType === 'image') {
                return file.size <= imageMaxSizeInByte;
            }
            return false;
        },

        getVideoDuration(file) {
            return new Promise((resolve, reject) => {
                try {
                    const video = document.createElement('video');
                    video.onloadedmetadata = () => {
                        resolve(video.duration);
                    };
                    video.onerror = (error) => {
                        reject(error);
                    };
                    video.src = URL.createObjectURL(file);
                } catch (error) {
                    console.error('Failed to get video duration:', error);
                    reject(error);
                }
            });
        },

        async saveStyleMetadata({ userId, style }) {
            try {
                if (!userId) {
                    throw new Error('セッションが正しくありません');
                }

                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/style`;

                console.info(JSON.stringify({
                    style: style,
                }));

                const response = await fetch(url, {
                    method: 'POST',
                    body: JSON.stringify({
                        style: style,
                    }),
                });
                const jsonRes = await response.json();

                if (response.ok) {
                    return jsonRes;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('メタデータの登録失敗', error);
                this.errorSavePost = 'アップロードに失敗しました';
            }
        },
        async deleteStyle({ userId, style }) {
            try {
                if (!userId) {
                    throw new Error('セッションが正しくありません');
                }

                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/style/${style.id}`;

                const response = await fetch(url, {
                    method: 'DELETE',
                });

                const jsonRes = await response.json();

                if (response.ok) {
                    return jsonRes;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('削除失敗', error);
                this.errorSavePost = '削除に失敗しました';
            }
        },

        async getPresignedUrl({ userId, postId, extension }) {
            try {
                //throw new Error('some error')
                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/presigned-url`;
                const queryParams = new URLSearchParams({
                    postId,
                    extension: extension.toLowerCase(),
                });
                const response = await fetch(`${url}?${queryParams}`, { method: 'GET' });
                const jsonRes = await response.json();

                if (response.ok) {
                    return jsonRes;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('署名付きポストURLの取得失敗', error);
                throw error;
            }
        },

        async uploadMediaFiles({ presignedUrl, form }) {
            try {
                const response = await fetch(presignedUrl, {
                    method: 'POST',
                    body: form,
                });
                if (response.ok) {
                    return true;
                } else {
                    throw new Error(response.error || response.message);
                }
            } catch (error) {
                console.error('S3へのアップロード失敗', error);
                this.errorSavePost = 'アップロードに失敗しました';
            }
        },

        async setPostToDelete(post) {
            this.postToDelete = post;

        },

        async deletePost({ postId, withMessage }) {
            this.clearMessages();
            this.deleting = true;

            try {
                const userId = this.sessionUser.idToken?.payload?.sub;
                const url = `${process.env.VUE_APP_API_ENDPOINT}/user/${userId}/post/${postId}`;
                const response = await fetch(url, { method: 'DELETE' });

                const jsonRes = await response.json();

                if (response.ok) {
                    this.deleteSuccess = withMessage ? 'ポストを削除しました' : '';
                    this.posts = await this.getStyle();
                    return jsonRes.posts;
                } else {
                    throw new Error(jsonRes.error || jsonRes.message);
                }
            } catch (error) {
                console.error('ポスト削除失敗', error);
                if (withMessage) {
                    this.errorDeletePost = 'ポストの削除に失敗しました';
                }
            } finally {
                this.deleting = false;
            }
        },

        clearMessages() {
            this.errorGetStyle = null;

            this.saveSuccess = null;
            this.errorSavePost = null;

            this.deleteSuccess = null;
            this.errorDeletePost = null;
        },
    }
};
</script>

<style>
.image-placeholder {
    width: 100%;
    padding-bottom: 75%;
    /* 4:3の縦横比を維持 */
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
}

.image-placeholder::before {
    color: #aaa;
    font-size: 1.5rem;
}

.image-container {
    width: 100%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
</style>
