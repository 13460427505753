<template>
  <div :class="{ 'top-page-style': isTopPage }">
    <div class="navbar navbar-expand-lg navbar-light bg-white">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand" @click="closeMenu">Salon Helper</router-link>
        <button class="navbar-toggler" type="button" @click="toggleMenu" :aria-expanded="isMenuOpen.toString()" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" :class="{ 'show': isMenuOpen }" id="navbarNav">
          <ul class="navbar-nav">
            <li v-if="!isUser && !isAdmin" class="nav-item ms-1 me-1">
              <router-link to="/register" class="nav-link" @click="closeMenu">新規登録</router-link>
            </li>
            <li v-if="!isUser && !isAdmin" class="nav-item ms-1 me-1">
              <router-link to="/login" class="nav-link" @click="closeMenu">ログイン</router-link>
            </li>
            <li v-if="!isUser && !isAdmin" class="nav-item ms-1 me-1">
              <router-link to="/overview" class="nav-link" @click="closeMenu">使い方</router-link>
            </li>
            <li v-if="!isUser && !isAdmin" class="nav-item ms-1 me-1">
              <router-link to="/privacy" class="nav-link" @click="closeMenu">プライバシーポリシー</router-link>
            </li>
            <li v-if="!isUser && !isAdmin" class="nav-item ms-1 me-1">
              <router-link to="/terms" class="nav-link" @click="closeMenu">利用規約</router-link>
            </li>
            <li v-if="isUser" class="nav-item ms-1 me-1">
              <router-link to="/posts" class="nav-link" @click="closeMenu">SNS投稿管理</router-link>
            </li>
            <li v-if="isUser" class="nav-item ms-1 me-1">
              <router-link to="/styles" class="nav-link" @click="closeMenu">スタイル管理</router-link>
            </li>
            <li v-if="isUser" class="nav-item ms-1 me-1">
              <router-link to="/blogs" class="nav-link" @click="closeMenu">ブログ管理</router-link>
            </li>
            <li v-if="isUser" class="nav-item ms-1 me-1">
              <router-link to="/insta/account" class="nav-link" @click="closeMenu">Instagramアカウント管理</router-link>
            </li>
            <li v-if="isUser" class="nav-item ms-1 me-1">
              <router-link to="/tiktok/account" class="nav-link" @click="closeMenu">TikTokアカウント管理</router-link>
            </li>
            <li v-if="isUser" class="nav-item ms-1 me-1">
              <router-link to="/shop" class="nav-link" @click="closeMenu">店舗情報管理</router-link>
            </li>
            <li v-if="isAdmin" class="nav-item ms-1 me-1">
              <router-link to="/users" class="nav-link" @click="closeMenu">ユーザー管理</router-link>
            </li>
            <li v-if="isAdmin || isUser" class="nav-item ms-1 me-1">
              <router-link to="/change-password" class="nav-link" @click="closeMenu">パスワード変更</router-link>
            </li>
            <li v-if="isAdmin || isUser" class="nav-item ms-1 me-1">
              <button @click="logout" class="btn btn-outline-danger">ログアウト</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mt-4 mx-auto" style="max-width: 720px;">
      <router-view />
    </div>
  </div>
  <div :class="{ 'top-page-foot': !isTopPage }">
    <div class="row m-0" style="width: 100%">
        <div class="col-12">
            <p class="m-5 text-center h3">さあ、あなたのお店も業務効率化を！</p>
            <a href="/register" class="btn btn-primary fw-bold">新規登録</a>
            <p class="m-3">　</p>
        </div>
    </div>
    <div class="row m-0" style="width: 100%">
        <div class="col-12 text-center">
            <a href="/privacy.html">プライバシーポリシー</a>　　
            <a href="/terms.html">利用規約</a>　　　
            @2024 Ityone
        </div>
        <p class="m-3">　</p>
    </div>
    
  </div>
</template>

<style>
  /* トップページ専用のスタイル */
  .top-page-style {
    background-color: #d8bfd8;
  }
  .top-page-foot {
    display: none;
  }
</style>


<script>
import userPool from '@/user-pool';
import { mapGetters, mapMutations } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  components: {
  },
  props: {
    msg: String
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    ...mapGetters([
      'isAdmin', 
      'isUser'
    ]),
  },
  methods: {
    ...mapMutations([
      'setUser',
      'clearUser',
    ]),
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    loggedIn() {
      this.checkUser();
    },
    logout() {
      this.closeMenu();

      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.signOut();
      }
      this.clearUser();
      console.log('ログアウトしました')
      this.$router.push({ path: '/login' });
    },
    checkUser() {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error('セッション取得に失敗しました', err);
            this.clearUser();
            return;
          }
          this.setUser(session);
        });
      } else {
        this.clearUser();
      }
    },
  },
  created() {
    this.checkUser();
  },
  setup() {
    const route = useRoute();
    const isTopPage = computed(() => route.path === '/');

    return {
      isTopPage,
    };
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.form-label {
  font-weight: bold;
}
</style>
