<template>
    <p class="mb-4">　</p>
    <h1 class="mb-4 mt-6">美容室のSEO対策を自動化</h1>
    <div class="row mb-4">
        <div class="col-6 align-self-center">
            <p class="ps-4 pe-4">容室のＳＥＯ対策でＳＮＳに投稿する作業が大変ではありませんか？</p>
            <p class="ps-4 pe-4">美容師さんには美容師の作業に専念してもらうことにより、あなたのお店の魅力をさらに上げましょう。</p> 
        </div>
        <div class="col-6 p-4">
            <img class="img-fluid rounded " src="/top_img1.jpg">
        </div>

    </div>

    <p class="m-5"></p>
    <h2 class="mb-4">毎日のSNS投稿、頼んでもやってくれないし<br/>自分でやるのも面倒</h2>
    <div class="row mb-4">
        <div class="col-6 p-4">
            <div class="card p-2" style="background-color: #f0f8ff;">
                <div class="card-body">
                    <div class="card-text">
                        <p class="h5">Salon Helperは毎日のルーティン作業を自動化するサービスです</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 p-4">
            <div class="card p-2" style="background-color: #f0f8ff;">
                <div class="card-body">
                    <div class="card-text">
                        <p class="h5">面倒なSNS投稿はSalon Helperにお任せください</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 p-4">
            <div class="card p-2" style="background-color: #f0f8ff;">
                <div class="card-body">
                    <div class="card-text">
                        <p class="h5">美容師が美容師に集中できるようになります</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 p-4">
            <div class="card p-2" style="background-color: #f0f8ff;">
                <div class="card-body">
                    <div class="card-text">
                        <p class="h5">SEO対策が進むことにより、集客アップします</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <p class="m-5"></p>
    <h2 class="mb-5">利用までは簡単４ステップ！</h2>
    <div class="card m-4">
        <div class="card-header fw-bold text-start text-white" style="background-color: #4169e1 ;">
            Step 1
        </div>
        <div class="card-body">
            <div class="card-title text-start h3">
                メールアドレスを登録
            </div>
            <div class="card-text">
                <div class="row mt-4">
                    <div class="col-3">
                        <img class="img-fluid" src="/top_mail.png" />
                    </div>
                    <div class="col-9 align-self-center">
                        <p class="h4">新規登録をクリックして<br/>メールアドレスを入力</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card m-4">
        <div class="card-header fw-bold text-start text-white" style="background-color: #4169e1 ;">
            Step 2
        </div>
        <div class="card-body">
            <div class="card-title text-start h3">
                SNSの情報を登録
            </div>
            <div class="card-text">
                <div class="row mt-4">
                    <div class="col-3">
                        <img class="img-fluid" src="/top_sns.png" />
                    </div>
                    <div class="col-9 align-self-center">
                        <p class="h4">TikTokとインスタの<br/>アカウント情報を登録</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card m-4">
        <div class="card-header fw-bold text-start text-white" style="background-color: #4169e1 ;">
            Step 3
        </div>
        <div class="card-body">
            <div class="card-title text-start h3">
                自動投稿内容を設定
            </div>
            <div class="card-text">
                <div class="row mt-4">
                    <div class="col-3">
                        <img class="img-fluid" src="/top_move.png" />
                    </div>
                    <div class="col-9 align-self-center">
                        <p class="h4">動画・画像と投稿テキストを<br/>あらかじめ設定</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card m-4">
        <div class="card-header fw-bold text-start text-white" style="background-color: #4169e1 ;">
            Step 4
        </div>
        <div class="card-body">
            <div class="card-title text-start h3">
                投稿タイミングの設定
            </div>
            <div class="card-text">
                <div class="row mt-4">
                    <div class="col-3">
                        <img class="img-fluid" src="/top_clock.png" />
                    </div>
                    <div class="col-9 align-self-center">
                        <p class="h4">あとは、投稿タイミングを設定すれば<br/>自動投稿がスタート</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p class="m-5">　</p>
    


</template>